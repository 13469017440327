import {
    createRouter,
    createWebHistory,
    RouteLocationNormalizedGeneric,
    RouteRecordRaw
} from "vue-router";
import { Preferences } from "@capacitor/preferences";
import { displayAlert } from "@/main";

import { useAuthStore } from "../stores/auth";
import { useGeneralStore } from "../stores/general";
import { PREFERENCES_KEYS } from "@/stores/constants";

let utms_checked: boolean = false;
async function checkUTMs(to: RouteLocationNormalizedGeneric) {
    if (utms_checked) return;

    const ALLOWED_UTMS = [
        "utm_medium",
        "utm_source",
        "utm_campaign",
        "utm_content",
        "utm_term",
        "campaign_id",
        "adgroup_id",
        "ad_id"
    ];

    const SANITIZED_VALS: Record<string, string>[] = [];
    for (let i = 0; i < ALLOWED_UTMS.length; i++) {
        if (to.query[ALLOWED_UTMS[i]]) {
            let v = to.query[ALLOWED_UTMS[i]];
            if (!v) continue;
            if (Array.isArray(v)) {
                v = v.join(",");
            }

            SANITIZED_VALS.push({
                k: ALLOWED_UTMS[i],
                v
            });
        }
    }
    if (SANITIZED_VALS.length > 0) {
        await Preferences.set({
            key: PREFERENCES_KEYS.USER_UTMS,
            value: JSON.stringify(SANITIZED_VALS)
        });
    }

    utms_checked = true;
}

const routes: RouteRecordRaw[] = [
    // 1. QUIZ
    {
        path: "/quiz/:url_name",
        name: "quiz",
        component: () => import("../views/quiz/quiz.vue")
    },

    // 2. MAIN APP
    // HOME
    {
        path: "/",
        name: "home",
        component: () => import("../views/home.vue")
    },

    // REDIRECTS
    {
        path: "/r/:token",
        name: "redirect",
        component: () => import("../views/r.vue")
    },

    // AUTH
    {
        path: "/auth/logowanie",
        name: "auth-signin",
        component: () => import("../views/auth/login.vue")
    },
    {
        path: "/auth/rejestracja",
        name: "auth-signup",
        component: () => import("../views/auth/register.vue")
    },
    {
        path: "/auth/dolacz-do-wizyty",
        name: "auth-join-appointment",
        component: () => import("../views/auth/join-appointment.vue")
    },

    // WELCOME
    {
        path: "/powitanie",
        name: "welcome",
        component: () => import("../views/welcome.vue")
    },

    // WIZ
    //#### FN1
    {
        path: "/wiz/fn1/rozpocznij",
        name: "wiz-fn1-onboarding",
        component: () => import("../views/wiz/fn1/onboarding.vue")
    },
    {
        path: "/wiz/fn1/termin-pierwszej-wizyty",
        name: "wiz-fn1-first-appointment-date",
        component: () => import("../views/wiz/fn1/first-appointment-date.vue")
    },
    {
        path: "/wiz/fn1/usluga-i-termin",
        name: "wiz-fn1-service-and-date",
        component: () => import("../views/wiz/fn1/service-and-date.vue")
    },
    {
        path: "/wiz/fn1/przeloz-wizyte",
        name: "wiz-fn1-appointment-reschedule",
        component: () => import("../views/wiz/fn1/appointment-reschedule.vue")
    },
    {
        path: "/wiz/fn1/platnosc/:id",
        name: "wiz-fn1-cscheckout",
        component: () => import("../views/wiz/fn1/cscheckout.vue")
    },
    {
        path: "/wiz/fn1/rekomendacje/:id",
        name: "wiz-fn1-recommendations",
        component: () => import("../views/wiz/fn1/recommendations.vue")
    },
    {
        path: "/wiz/fn1/oplac-wizyte/:id",
        name: "wiz-fn1-pay-appointment",
        component: () => import("../views/wiz/fn1/pay-appointment.vue")
    },

    // WIZ
    //#### FN2
    {
        path: "/wiz/fn2/rozpocznij",
        name: "wiz-fn2-onboarding",
        component: () => import("../views/wiz/fn2/onboarding.vue")
    },
    {
        path: "/wiz/fn2/rekomendacje/:id",
        name: "wiz-fn2-recommendations",
        component: () => import("../views/wiz/fn2/recommendations.vue")
    },

    // APPOINTMENTS
    {
        path: "/wizyty",
        name: "appointments",
        component: () => import("../views/appointments.vue")
    },

    // VIDEO-CALLS
    {
        path: "/vc/:id",
        name: "vc",
        component: () => import("../views/video-call/index.vue")
    },
    {
        path: "/vc/:id/tw/:vc_credentials",
        name: "vc_tw",
        component: () => import("../views/video-call/call-twilio.vue")
    },
    {
        path: "/vc/:appointment_id/tw2/:credentials",
        name: "vc2-twilio-2",
        component: () => import("../views/video-call/video-call-twilio-2.vue")
    },

    // CHAT
    {
        path: "/rozmowy",
        name: "chats",
        component: () => import("../views/chats.vue")
    },

    {
        path: "/rozmowy/:id",
        name: "chat",
        component: () => import("../views/chat.vue")
    },

    // PAKIETY
    {
        path: "/kup-pakiet",
        name: "bundles",
        component: () => import("../views/bundles.vue")
    },

    // CENTRUM POMOCY
    {
        path: "/centrum-pomocy",
        name: "help-center",
        component: () => import("../views/help-center/index.vue")
    },
    {
        path: "/centrum-pomocy/zgloszenia/:id",
        name: "help-center-helpdesk-thread",
        component: () => import("../views/help-center/helpdesk-thread.vue")
    },

    // PROFIL UŻYTKOWNIKA
    {
        path: "/moje-konto",
        name: "my-account",
        component: () => import("../layouts/MyAccount.vue"),
        children: [
            {
                path: "ogolne",
                name: "my-account-general-data",
                component: () => import("../views/my-account/general-data.vue")
            },
            {
                path: "bezpieczenstwo-i-logowanie",
                name: "my-account-login-data-and-security",
                component: () => import("../views/my-account/login-data-and-security.vue")
            },
            {
                path: "powiadomienia",
                name: "my-account-notifications",
                component: () => import("../views/my-account/notifications.vue")
            },
            {
                path: "platnosci",
                name: "my-account-payments",
                component: () => import("../views/my-account/payments.vue")
            },
            {
                path: "polecaj-znajomych",
                name: "my-account-recommend-friends",
                component: () => import("../views/my-account/recommend-friends.vue")
            },
            {
                path: "lista-wyslanych-polecen",
                name: "my-account-recommend-friends-sent",
                component: () => import("../views/my-account/recommend-friends-sent.vue")
            },
            {
                path: "doswiadczenie",
                name: "my-account-experience",
                component: () => import("../views/my-account/experience.vue")
            },
            {
                path: "edukacja",
                name: "my-account-education",
                component: () => import("../views/my-account/education.vue")
            },
            {
                path: "produkty",
                name: "my-account-products",
                component: () => import("../views/my-account/products.vue")
            },
            {
                path: "kalendarz-dostepnosci",
                name: "my-account-availability",
                component: () => import("../views/my-account/availability.vue")
            },
            {
                path: "ustawienia",
                name: "my-account-settings",
                component: () => import("../views/my-account/settings.vue")
            },
            {
                path: "uzytkownicy-powiazani",
                name: "my-account-linked-users",
                component: () => import("../views/my-account/linked-users.vue")
            }
        ]
    },

    // TERAPEUTA
    {
        path: "/terapeuta/rozliczenia",
        name: "therapist-settlements",
        component: () => import("../views/therapist/settlements.vue")
    },

    // STREFA WIEDZY
    {
        path: "/strefa-wiedzy/blog",
        name: "knowledge-blog",
        component: () => import("../views/knowledge/blog.vue")
    },
    {
        path: "/strefa-wiedzy/blog/:id",
        name: "knowledge-blog-post",
        component: () => import("../views/knowledge/blog-post.vue"),
        props: true
    },

    // TERAPEUCI
    {
        path: "/u/terapeuci/:id",
        name: "therapists-profile",
        component: () => import("../views/therapists/profile.vue")
    },

    // ZAPROSZENIA
    {
        path: "/uiaccept/v1",
        name: "user-invite",
        component: () => import("../views/user-invite.vue")
    },

    // FEEDBACK PREVIEW
    {
        path: "/survey-preview/:id",
        name: "survey-preview",
        component: () => import("../views/survey-preview.vue")
    },

    // PROGRAM AFILIACYJNY
    {
        path: "/afp/:id",
        name: "affprog-invite",
        component: () => import("../views/affprog-invite.vue")
    },

    // PROCES REKRUTACJI
    {
        path: "/rekrutacja-terapeutow/:id",
        name: "therapist-recruitment",
        component: () => import("../views/therapist-recruitment.vue")
    },

    // 404
    {
        path: "/:pathMatch(.*)*",
        name: "e404",
        component: () => import("../views/e404.vue")
    },

    // OFFLINE
    {
        path: "/offline",
        name: "offline",
        component: () => import("../views/offline.vue")
    },

    // NOTCH TEST
    {
        path: "/notch-test",
        name: "notch-test",
        component: () => import("../views/notch-test.vue")
    }
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

const PUBLIC_ROUTE_NAMES: string[] = [
    "offline",
    "e404",
    "documents-privacy",
    "documents-terms",
    "welcome",
    "quiz",
    "quiz-board",
    "auth-signin",
    "auth-signup",
    "auth-join-appointment",
    "redirect",
    "affprog-invite",
    "therapist-recruitment"
];

router.beforeEach(async to => {
    const generalStore = useGeneralStore();

    // 1. Jeżeli apka się jeszcze nie zbootowała to grzecznie czekamy
    await generalStore.app_boot_promise;

    // 2. Sprawdzamy UTMs
    await checkUTMs(to);

    // 3. Jeżeli użytkownik próbuje się dostać na zabezpieczony route, to sprawdzamy czy jest zalogowany
    const authStore = useAuthStore();
    const TO_NAME = to.name ? to.name.toString() : "";
    if (PUBLIC_ROUTE_NAMES.indexOf(TO_NAME) === -1 && !authStore.is_logged_id) {
        if (TO_NAME === "home" && !to.query.pla) {
            return {
                name: "welcome"
            };
        } else {
            displayAlert.error({
                title: "Brak dostępu",
                message: "Zaloguj się, aby uzyskać dostęp do tej części aplikacji"
            });

            return {
                name: "auth-signin",
                query: {
                    rback: to.fullPath
                }
            };
        }
    }

    // 4. Git, może lecieć gdzie zechciał
    return true;
});
router.afterEach(to => {
    if (to.name && typeof to.name === "string") {
        const generalStore = useGeneralStore();
        if (
            generalStore.navigation_stack.length === 0 ||
            generalStore.navigation_stack[generalStore.navigation_stack.length - 1] != to.name
        ) {
            generalStore.pushToNavigationStack(to.name);
        }
    }
});

export default router;
